import { VideoContext } from "../video";
import axios from 'axios';
import { API_URL } from "../config";

const info = {
    name: 'plugin-network-test'
}

class PluginNetworkTest {

    constructor(jsPsych) {
        this.jsPsych = jsPsych;
        this.videoContact = new VideoContext(jsPsych)
    }

    checkAudio() {
        return new Promise((resolve, reject) => {
            let stream = this.jsPsych.pluginAPI.getCameraStream();
            // Check video has audio or not 
            const audioContext = new AudioContext();
            const mediaStreamSource = audioContext.createMediaStreamSource(stream);
            const analyser = audioContext.createAnalyser();

            mediaStreamSource.connect(analyser);

            analyser.fftSize = 256;
            const bufferLength = analyser.frequencyBinCount;
            const dataArray = new Uint8Array(bufferLength);

            let timeout_id = setTimeout(() => {
                resolve(false);
            }, 30000);

            function checkSoundLevel() {
                analyser.getByteFrequencyData(dataArray);
                const sum = dataArray.reduce((a, b) => a + b, 0);
                const average = sum / bufferLength;

                if (average > 32) {
                    clearTimeout(timeout_id);
                    resolve(true);
                } else {
                    requestAnimationFrame(checkSoundLevel);
                }
            }
            checkSoundLevel();
        });
    }

    videoTest(display_element, trial) {
        display_element.innerHTML = `
              <video autoplay playsinline id="jspsych-mirror-camera-video" width="auto" height="auto" 'style="transform: rotateY(180deg);"}></video>
              <p> Recording... </p>
        `;
        display_element.querySelector("#jspsych-mirror-camera-video").srcObject = this.jsPsych.pluginAPI.getCameraStream();
        this.videoContact.start();
        setTimeout(() => {
            this.videoContact.stop().then((blob) => {
                display_element.innerHTML = `
                    <p> Video recorded successfully. Please wait. </p>
                `;

                // Check file size
                if (blob.size < 1024 * 1024 * 1) {
                    display_element.innerHTML = `
                        <p> Video file size is too small. Please contact the experimenter. </p>
                    `;
                    return;
                }

                function getUuid() {
                    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c => (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16));
                }


                let formdata = new FormData();
                formdata.append("video", blob, "video." + VideoContext.settings.fileExtension);
                formdata.append("studyId", "NETWORK_TEST")
                formdata.append("label", getUuid())
                formdata.append("participantId", "UNKNOWN")
                return axios.post(`${API_URL}/uploadVideo`, formdata, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }).then((response) => {
                    this.jsPsych.finishTrial({})
                }).catch((error) => {
                    console.error(error);
                    display_element.innerHTML = `
                        <p> Error to upload video. Please contact the experimenter. </p>
                    `;
                })
            })
        }, 20000);
    }

    async trial(display_element, trial) {
        display_element.innerHTML = `
            <p> Please say something, Checking audio... </p>
        `;

        let audioCheck = await this.checkAudio();
        if (!audioCheck) {
            display_element.innerHTML = `
                <p> No audio detected. Please contact the experimenter. </p>
            `;
            return;
        } else {
            display_element.innerHTML = `
                <p> Audio detected. Video recording will start soon.</p>
            `;
            setTimeout(() => {
                this.videoTest(display_element, trial);
            }, 3000);
        }

    }

}

PluginNetworkTest.info = info;

export default PluginNetworkTest;
