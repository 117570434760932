import { ParameterType } from "jspsych";

const info = {
    name: 'plugin-rate',
    parameters: {
        minScore: {
            type: ParameterType.INT,
            default: -10
        },
        maxScore: {
            type: ParameterType.INT,
            default: 10
        },
        prompt: {
            type: ParameterType.STRING,
            default: null
        },
        stimulus: {
            type: ParameterType.STRING,
            default: ''
        },
        duration: {
            type: ParameterType.INT,
            default: 999000
        },
        labels: {
            type: ParameterType.STRING,
            array: true,
            default: ['Extremely Negative', 'Neutral', 'Extremely Positive']
        }
    }
}

class PluginRate {

    constructor(jsPsych) {

        this.jsPsych = jsPsych;
    }

    trial(display_element, trial) {

        let scale_items = [];

        for (let i = trial.minScore; i <= trial.maxScore; i++) {
            scale_items.push(`<div id='rate-button-${i}' style="width: 44px; font-size: 1.2em">${i}</div>`);
        }

        let prompt = '';

        if (trial.prompt !== null) {
            prompt = `<p style="text-align: start;width: 80%;padding-left: 10%;padding-right: 10%;margin-bottom: 12px; font-weight: bold">${trial.prompt}</p>`
        }

        let stimulus = `
        <p style="text-align: start;width: 80%;padding-left: 10%;padding-right: 10%;margin-bottom: 92px;"> ${trial.stimulus} </p>
        `

        let scale = `
        <div style="display: flex;flex-direction: row;align-items: center;width: 80%;justify-content: space-between;font-weight: bold;">${scale_items.join('')}</div>
        `

        let picture = `
        <div style="display: flex;flex-direction: row;align-items: center;width: 78%;padding: 1%;">
            <div style="width: 4px;height: 48px;background: #000;"></div>
            <div style="flex: 1;height: 4px;background: #000;"></div>
            <div style="width: 4px;height: 48px;background: #000;"></div>
            <div style="flex: 1;height: 4px;background: #000;"></div>
            <div style="width: 4px;height: 48px;background: #000;"></div>
        </div>`

        let label = `
        <div style="display: flex;flex-direction: row;align-items: center;width: 84%;justify-content: space-between;font-weight: bold;">
            <div style="width: 8%;">${trial.labels[0]}</div>
            <div style="width: 8%;">${trial.labels[1]}</div>
            <div style="width: 8%;">${trial.labels[2]}</div>
        </div>
        `

        display_element.innerHTML = `<div style="
        display: flex;
        width: 95vw;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 1.2em;
        ">` + prompt + stimulus + scale + picture + label + `</div>`;

        let timer_id = setTimeout(() => {
            this.jsPsych.finishTrial({
                response: undefined,
            });
        }, trial.duration);

        for (let i = trial.minScore; i <= trial.maxScore; i++) {
            display_element.querySelector(`#rate-button-${i}`).addEventListener('click', () => {
                clearTimeout(timer_id);
                this.jsPsych.finishTrial({
                    response: i
                });
            });
        }

    }

}

PluginRate.info = info;

export default PluginRate;
