import { initJsPsych } from 'jspsych';
import jsPsychPreload from '@jspsych/plugin-preload';
import jsPsychSurvey from '@jspsych/plugin-survey';
import jsPsychHtmlKeyboardResponse from '@jspsych/plugin-html-keyboard-response';
import jsPsychHtmlButtonResponse from '@jspsych/plugin-html-button-response';
import jsPsychExtensionRecordVideo from '@jspsych/extension-record-video';
import jsPsychInitializeCamera from '@jspsych/plugin-initialize-camera';
import jsPsychMirrorCamera from '@jspsych/plugin-mirror-camera';
import jsPsychFullScreen from '@jspsych/plugin-fullscreen'
import jsRate from './plugins/plugin-rate.js';
import jsNetowrkTest from './plugins/plugin-network-test.js';
import jsUploadingTaskChecker from './plugins/plugin-uploading-task-checker.js';
import { VideoContext } from './video.js';
import axios from 'axios'
import 'jspsych/css/jspsych.css'
import '@jspsych/plugin-survey/css/survey.css'
import { API_URL } from './config.js';
import TaskData, { UploadingTask } from './data.js'
import './index.css'
import { show_picture_template } from './html-template.js'

const PICTURE_DURATION = 15000
const CROSS_DURATION = 3000
const SCORE_DURATION = 10000

const DISPLAY_LABEL_MAP = {
    "baseline": "BE YOURSELF",
    "suppress": "CONCEAL",
    "enhance": "EXPRESS"
}

function generateTextBlock(text) {
    return {
        type: jsPsychHtmlButtonResponse,
        stimulus: `${text}`,
        choices: ["Continue"]
    }
}

function uploadVideo(video, study_id, participantId, label) {
    let formdata = new FormData();
    formdata.append("video", video, "video." + VideoContext.settings.fileExtension);
    formdata.append("studyId", study_id)
    formdata.append("label", label)
    formdata.append("participantId", participantId)
    return axios.post(`${API_URL}/uploadVideo`, formdata, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
}

function uploadMetadata(metadata, study_id, participantId, label) {
    let formdata = new FormData();
    formdata.append("metadata", JSON.stringify(metadata));
    formdata.append("studyId", study_id)
    formdata.append("label", label)
    formdata.append("participantId", participantId)
    return axios.post(`${API_URL}/uploadMetadata`, formdata, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
}


export function runTest(options) {

    const { query_data, study_id, enable_onedrive, enable_downloading, metadata_id } = options;
    let task_data = new TaskData();
    task_data.setBaseMetadata(query_data.metadata);
    task_data.setMetadataID(metadata_id);
    // Generate date string
    let date = new Date();
    let date_string = date.toISOString().slice(0, 10) + "_" + date.toISOString().slice(11, 19).replace(/:/g, "-");


    function saveAndUploadBlockData(data) {

        if (enable_onedrive) {
            return uploadData(
                query_data.metadata,
                data.video,
                data.valence,
                data.index,
                data.rating,
                data.participantId,
                study_id
            )
        } else {
            return Promise.resolve()
        }
    }

    const jsPsych = initJsPsych({
        extensions: [
            { type: jsPsychExtensionRecordVideo, params: {} },
        ],
    });

    const asyncTasks = []
    var participantId

    let init_camera = {
        type: jsPsychInitializeCamera,
        include_audio: true,
        mime_type: VideoContext.settings.mimeType,
        height: { ideal: 480, min: 480, max: 720 },
        width: { ideal: 640, min: 640, max: 1280 }
    }

    let mirror_camera = {
        type: jsPsychMirrorCamera,
        prompt: "<p>Adjust your camera so that you can see your face clearly. And please make sure you can hear the echo of your own voice.</p>",
    }

    let network_test = {
        timeline: [
            {
                type: jsPsychHtmlButtonResponse,
                stimulus: `
                 <p>We will perform a brief network check to ensure your device is correctly set up for this task. You will be recorded for 20 secs. Please follow our prompts.</p>
                 <p>Click on the button to start recording.</p>
                `,
                choices: ["Continue"],
            },
            {
                type: jsNetowrkTest,
            },
            {
                type: jsPsychHtmlButtonResponse,
                stimulus: `
                <p>Network test is complete. Thank you for cooperating.</p>
                <p>Click on the button to continue.</p>
                `,
                choices: ["Continue"],
            }
        ]
    }

    let survey = {
        type: jsPsychSurvey,
        pages: [
            [
                {
                    type: "html",
                    prompt: `
                    <h3 class='instruction-text'>Please read the instructions below out loud.</h3>
                    <p class='instruction-text'>In this task, it is tempting to rush by clicking the “ok” or “continue” buttons. Whilst tempting, please wait for the experimenter to confirm when you can proceed to the next page.</p>
                    <p class='instruction-text'>It is important you listen to and wait for the experimenter’s instructions before continuing.</p>`
                },
                {
                    type: 'text',
                    prompt: "Please enter your ID:",
                    validation: "[A-Za-z0-9]*",
                    input_text: 'text',
                    required: true
                }
            ]
        ],
        button_label_finish: 'OK',
        on_load: (trial) => {
            // Ugly code, But just hack the plugin make the button disply after 20secs
            jsPsych.pluginAPI.setTimeout(() => {
                let complete_button = document.querySelector("#sv-nav-complete > div > input")
                complete_button.style.visibility = 'hidden'
                jsPsych.pluginAPI.setTimeout(() => complete_button.style.visibility = 'visible', 20000)
            }, 0)
        },
        on_finish: (data) => {
            participantId = data.response.P0_Q1
            task_data.setParticipantID(participantId);
        }

    };

    let fullscreen = {
        type: jsPsychFullScreen,
        fullscreen_mode: true
    }

    let instructions_begin = {
        timeline: [
            {
                type: jsPsychHtmlButtonResponse,
                stimulus: `
                <p class='no-center-text'><strong>Please read the instructions below out loud</strong></p>
                <p class='no-center-text'>Now, we are going to do something a bit different and it may seem unusual. In a moment, I am going to show you a series of images and as you watch each one of them, I want you to tell me about what you are looking at. Some of these images are positive and some are negative. </p>
                <p class='no-center-text'>After each series of images, I am going to ask you to rate on a scale on the computer screen how you feel. This scale ranges from -10 to 10. -10 means you feel extreme negative emotion, 0 means you feel neutral and 10 means you feel extreme positive emotion. Please make this rating by <strong>clicking a number on your screen</strong> as quickly as you can.</p>
                `,
                choices: ["Continue"],
            },
            {
                type: jsPsychHtmlButtonResponse,
                stimulus: `
                <p class='no-center-text'><strong>Please read the instructions below out loud</strong></p>
                <p class='no-center-text'>You will be recorded throughout this task. We will have another researcher at a later time examine your recording, their aim is to detect what you are feeling as you watch these images.</p>
                <p class='no-center-text'>In some tasks, I want you to do your best to suppress or hide your feelings so the researcher cannot easily guess what you are feeling. That’s right, I want you to conceal or disguise what you are feeling so this researcher cannot tell what your true feelings are as you describe the images.</p>
                <p class='no-center-text'>In another task, I want you to do the opposite and really communicate what you are feeling. Please express what you are feeling in every way, so the researcher can tell from how you are behaving and speaking what these feelings are as you describe these images.</p>
                <p class='no-center-text'>Finally, there will also be another task where the researcher will not be reviewing the recording. I want you to just watch and describe the images as you normally would.</p>
                <p class='no-center-text'>Whilst completing these tasks, try to speak for as long as you can.</p>
                `,
                choices: ["Continue"]
            }
        ]
    }

    let instructions_practice = {
        type: jsPsychHtmlButtonResponse,
        stimulus: `<p>Let’s have a few practise goes.</p>`,
        choices: ["Continue"]
    };

    let instructions_suppress = {
        type: jsPsychHtmlButtonResponse,
        stimulus: `
    <h1>CONCEAL</h1>
    <p class='no-center-text'><strong>Please read the instructions below out loud</strong></p>
    <p class='no-center-text'>In the following sequence, I want you to conceal or hide your feelings in any way you can as you describe each image.</p>
    <p class='no-center-text'>Do your best to behave and speak in a way that will make it difficult for the researcher to guess what you are really feeling.</p>
    <p class='no-center-text'>Remember, after each series of images you will be asked to give a rating on what you are feeling.</p>
    `,
        choices: ["Continue"]
    }

    let instructions_enhance = {
        type: jsPsychHtmlButtonResponse,
        stimulus: `
    <h1>EXPRESS</h1>
    <p class='no-center-text'><strong>Please read the instructions below out loud</strong></p>
    <p class='no-center-text'>For the next set of images, I want you to express as fully as you can the emotions you feel as you describe the images.</p>
    <p class='no-center-text'>That’s right, speak and behave in a way so that the researcher who looks at this recording really knows what you are feeling. </p>
    <p class='no-center-text'>Then you will be asked to rate how you are feeling after each series of images.</p>
    `,
        choices: ["Continue"]
    }

    let instructions_baseline = {
        type: jsPsychHtmlButtonResponse,
        stimulus: `
    <h1>BE YOURSELF</h1>
    <p class='no-center-text'><strong>Please read the instructions below out loud</strong></p>
    <p class='no-center-text'>No researcher will be reviewing the recording, so you can just be yourself as you describe the images. </p>
    <p class='no-center-text'>Remember you will be asked to rate how you are feeling after each series of images.</p>
    `,
        choices: ["Continue"]
    }

    let show_picture = {
        type: jsPsychHtmlKeyboardResponse,
        stimulus: () => {
            return show_picture_template(jsPsych.timelineVariable("picture_type"), jsPsych.timelineVariable('picture_data'))
        },
        choices: "NO_KEYS",
        trial_duration: PICTURE_DURATION,
    }

    let rate = {
        type: jsRate,
        stimulus: "Please rate how you feel at this present moment. <strong>Click a number on the screen</strong> as quickly as you can.",
        duration: SCORE_DURATION
    };


    let cross = {
        type: jsPsychHtmlKeyboardResponse,
        stimulus: "<h1>+</h1>",
        choices: "NO_KEYS",
        trial_duration: CROSS_DURATION
    }


    // Practice
    let stimuli_practice_negative = query_data.images.practiceTrials[0].map((image_data) => {
        return {
            picture_type: "CONCEAL",
            picture_data: image_data
        }
    })

    let stimuli_practice_positive = query_data.images.practiceTrials[1].map((image_data) => {
        return {
            picture_type: "EXPRESS",
            picture_data: image_data
        }
    })


    let procedure_practice = {
        timeline: [
            instructions_practice,
            instructions_suppress,
            cross,
            {
                timeline: [show_picture],
                timeline_variables: stimuli_practice_negative
            },
            rate,
            instructions_enhance,
            {
                timeline: [show_picture],
                timeline_variables: stimuli_practice_positive
            },
            rate
        ]
    }

    // Test
    let instructions_erf = {
        type: jsPsychHtmlButtonResponse,
        stimulus: `
    <p>OK, good. You seem to have the idea. </p>
    <p>Now let’s start the task. </p>
    `,
        choices: ["Continue"]
    }

    let procedure_test = {
        timeline: [
            instructions_erf
        ]
    }


    for (let index in query_data.metadata.instructions) {
        let ratings = []
        let videoContext1 = new VideoContext(jsPsych);
        let videoContext2 = new VideoContext(jsPsych);
        let instruction_name = query_data.metadata.instructions[index];
        switch (instruction_name) {
            case "suppress":
                procedure_test.timeline.push(instructions_suppress);
                break;
            case "enhance":
                procedure_test.timeline.push(instructions_enhance);
                break;
            case "baseline":
                procedure_test.timeline.push(instructions_baseline);
        }
        procedure_test.timeline.push({
            timeline: [
                {
                    timeline: [
                        cross,
                        {
                            timeline: [show_picture],
                            timeline_variables: query_data.images.images[index][0].map((image_data) => {
                                return {
                                    picture_type: DISPLAY_LABEL_MAP[instruction_name],
                                    picture_data: image_data,
                                }
                            })
                        },
                        {
                            ...rate,
                            on_finish: (data) => {
                                ratings[0] = data.response == null ? 999 : Number(data.response)
                            }
                        }
                    ],
                    on_timeline_start: () => {
                        videoContext1.start()
                    },
                    on_timeline_finish: () => {
                        videoContext1.stop()
                            .then((data) => {
                                task_data.addVideoData("block_" + index + "_" + query_data.metadata.valence[0], data)
                                task_data.addTextData("rating_" + index + "_" + query_data.metadata.valence[0], ratings[0])
                                asyncTasks.push(
                                    new UploadingTask(
                                        "video_block" + index + "_" + query_data.metadata.valence[0],
                                        () => { return uploadVideo(data, study_id, participantId, date_string + "_" + index + "_" + query_data.metadata.valence[0]) }
                                    )
                                )
                                uploadMetadata(task_data.getMetadata(), study_id, participantId, date_string)
                            })
                    }
                },
                {
                    timeline: [
                        cross,
                        {
                            timeline: [show_picture],
                            timeline_variables: query_data.images.images[index][1].map((image_data) => {
                                return {
                                    picture_type: DISPLAY_LABEL_MAP[instruction_name],
                                    picture_data: image_data,
                                }
                            })
                        },
                        {
                            ...rate,
                            on_finish: (data) => {
                                ratings[1] = data.response == null ? 999 : Number(data.response)
                            }
                        }
                    ],
                    on_timeline_start: () => {
                        videoContext2.start()
                    },
                    on_timeline_finish: () => {
                        videoContext2.stop()
                            .then((data) => {
                                task_data.addVideoData("video_" + index + "_" + query_data.metadata.valence[1], data)
                                task_data.addTextData("rating_" + index + "_" + query_data.metadata.valence[1], ratings[1])
                                asyncTasks.push(
                                    new UploadingTask(
                                        "video_block" + index + "_" + query_data.metadata.valence[1],
                                        () => { return uploadVideo(data, study_id, participantId, date_string + "_" + index + "_" + query_data.metadata.valence[1]) }
                                    )
                                )
                                uploadMetadata(task_data.getMetadata(), study_id, participantId, date_string)
                            }
                            )
                    }
                },
            ],
        })

        // Add feedback
        switch (index) {
            case "0":
                procedure_test.timeline.push(generateTextBlock("<p>Excellent. Now, I want you to keep this up. </p>"));
                break;
            case "1":
                procedure_test.timeline.push(generateTextBlock("<p>Great. Now I want you to keep this up for the following set of images.</p>"));
                break;
        }
    }


    let videoContext_positive_mood_induction = new VideoContext(jsPsych);
    // Positive Mood Induction
    let procedure_positive_mood_induction = {
        timeline: [
            {
                type: jsPsychHtmlButtonResponse,
                stimulus: `
                    <p class='no-center-text'><strong>Please read the instructions below out loud.</strong></p>
                    <p class='no-center-text'>Now, I want you to think of your absolute best, happiest memory. It can be about anything in your life.</p>
                `,
                choices: ["Continue"]
            },
            {
                type: jsPsychHtmlButtonResponse,
                stimulus: `
                    <p class='no-center-text'><strong>Please read the instructions below out loud.</strong></p>
                    <p class='no-center-text'>I want you to close your eyes and imagine this memory as clearly as you can. Allow images of it to build up in your mind. Take a few moments to focus on this memory. </p>
                `,
                choices: ["Continue"],
                button_delay: 40000
            },
            {
                type: jsPsychHtmlButtonResponse,
                stimulus: `
                    <p class='no-center-text'><strong>Please read the instructions below out loud.</strong></p>
                    <p class='no-center-text'>Please tell me all about this memory. Click the record button below to start.</p>
                `,
                choices: ["Start Recording"],
            },
            {
                type: jsPsychHtmlButtonResponse,
                choices: [],
                stimulus: `Recording has started.`,
                trial_duration: 60000,
                on_start: () => {
                    videoContext_positive_mood_induction.start()
                },
                on_finish: (data) => {
                    videoContext_positive_mood_induction.stop()
                        .then((data) => {
                            task_data.addVideoData("positive_mood_induction", data)
                            asyncTasks.push(
                                new UploadingTask(
                                    "video_final",
                                    () => { return uploadVideo(data, study_id, participantId, date_string + "_positive_mood_induction") }
                                )
                            )
                        })
                }
            },
            {
                type: jsRate,
                minScore: 0,
                maxScore: 10,
                prompt: "Please read the instructions below out loud.",
                stimulus: "Now, I want you to rate how happy you were as you were thinking about this memory. Please click a number on the scale below from 0 (not at all happy) to 10 (extremely happy).",
                labels: ['Not at all happy', '', 'Extremely happy'],
                on_finish: (data) => {
                    task_data.addTextData("rating_positive_mood_induction_happy", data.response)
                    uploadMetadata(task_data.getMetadata(), study_id, participantId, date_string)
                }
            },
            {
                type: jsRate,
                minScore: 0,
                maxScore: 10,
                prompt: "Please read the instructions below out loud.",
                stimulus: "How vivid was this memory? Please click a number on the scale below from 0 (not at all vivid) to 10 (extremely vivid).",
                labels: ['Not at all vivid', '', 'Extremely vivid'],
                on_finish: (data) => {
                    task_data.addTextData("rating_positive_mood_induction_vivid", data.response)
                    asyncTasks.push(
                        new UploadingTask(
                            "meta_data",
                            () => { return uploadMetadata(task_data.getMetadata(), study_id, participantId, date_string) }
                        )
                    );
                }
            },
            {
                type: jsUploadingTaskChecker,
                tasks: () => {return asyncTasks},
            },
            {
                type: jsPsychHtmlButtonResponse,
                stimulus: `<p>Thank you for participating. You have completed the task.</p>`,
                choices: ["OK"],
            },
        ]
    }

    const timeline = [
        fullscreen,
        init_camera,
        mirror_camera,
        network_test,
        survey,
        instructions_begin,
        procedure_practice,
        procedure_test,
        procedure_positive_mood_induction
    ]

    jsPsych.run(timeline);
}
