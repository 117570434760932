import { FFmpeg } from "@ffmpeg/ffmpeg";
import { fetchFile, toBlobURL } from '@ffmpeg/util';

let ffmpeg = new FFmpeg();

async function initFFmpeg() {
    const baseURL = 'https://unpkg.com/@ffmpeg/core@0.12.4/dist/umd'
    ffmpeg = new FFmpeg();
    ffmpeg.on('log', ({ message }) => { 
        console.log(message);
    });
    // toBlobURL is used to bypass CORS issue, urls with the same
    // domain can be used directly.
    await ffmpeg.load({
        coreURL: await toBlobURL(`${baseURL}/ffmpeg-core.js`, 'text/javascript'),
        wasmURL: await toBlobURL(`${baseURL}/ffmpeg-core.wasm`, 'application/wasm'),
    });
}

export { ffmpeg, initFFmpeg };