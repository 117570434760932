// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tip-image {
    width: 80%;
}

.no-center-text {
    text-align: left;
}

h1{
    font-size: 2.4em;
    margin: 2em;
    font-family: Arial, Helvetica, sans-serif;
}

#jspsych-html-button-response-stimulus {
    margin: 2em;
}

p {
    font-family: Arial, Helvetica, sans-serif;
}

#jspsych-html-button-response-stimulus p {
    max-width: 60vw;
    font-size: 1.2em;
}

.picture {
    height: 70vh;
    width: 70vw;
    object-fit: contain;
}


#jspsych-html-keyboard-response-stimulus p{
    max-width: 60vw;
    font-size: 1.2em;
    margin: auto auto 2em auto;
}

.instruction-text {
    font-size: 1.2em;
    color: black;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;IACI,UAAU;AACd;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;IAChB,WAAW;IACX,yCAAyC;AAC7C;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,yCAAyC;AAC7C;;AAEA;IACI,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,YAAY;IACZ,WAAW;IACX,mBAAmB;AACvB;;;AAGA;IACI,eAAe;IACf,gBAAgB;IAChB,0BAA0B;AAC9B;;AAEA;IACI,gBAAgB;IAChB,YAAY;AAChB","sourcesContent":[".tip-image {\n    width: 80%;\n}\n\n.no-center-text {\n    text-align: left;\n}\n\nh1{\n    font-size: 2.4em;\n    margin: 2em;\n    font-family: Arial, Helvetica, sans-serif;\n}\n\n#jspsych-html-button-response-stimulus {\n    margin: 2em;\n}\n\np {\n    font-family: Arial, Helvetica, sans-serif;\n}\n\n#jspsych-html-button-response-stimulus p {\n    max-width: 60vw;\n    font-size: 1.2em;\n}\n\n.picture {\n    height: 70vh;\n    width: 70vw;\n    object-fit: contain;\n}\n\n\n#jspsych-html-keyboard-response-stimulus p{\n    max-width: 60vw;\n    font-size: 1.2em;\n    margin: auto auto 2em auto;\n}\n\n.instruction-text {\n    font-size: 1.2em;\n    color: black;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
