import { fetchFile } from "@ffmpeg/util";
import { ffmpeg } from "./ffmpeg";
export class VideoContext {

    static settings = null;
    mediaRecorder = null;
    data = null;

    constructor(jsPhych) {
        if (VideoContext.settings == null) {
            throw new Error('VideoContext is not initialized. Please call VideoContext.init() first.')
        }
        this.jsPhych = jsPhych
        this.chunks = []
    }

    start() {
        let self = this
        this.mediaRecorder = new MediaRecorder(this.jsPhych.pluginAPI.getCameraRecorder().stream, { 
            mimeType: VideoContext.settings.mimeType,
            bitsPerSecond: 2 * 1024 * 1024
        });
        console.log(this.mediaRecorder)
        this.mediaRecorder.ondataavailable = function (e) {
            self.chunks.push(e.data);
        };
        this.mediaRecorder.start();

    }

    stop() {
        let self = this
        return new Promise((resolve, reject) => {
            self.mediaRecorder.onstop = function () {
                let blob = new Blob(self.chunks, { type: VideoContext.settings.mimeType });
                async function test() {
                    resolve(blob)
                };
                test()
            };
            self.mediaRecorder.stop()
        })
    }

    readyHelper(resolve) {
        setTimeout(() => {
            if (this.data != null) {
                resolve()
            } else {
                this.readyHelper(resolve)
            }
        }, 64)
    }

    ready() {
        let self = this
        return new Promise((resolve, reject) => {
            if (self.data != null) {
                resolve()
            } else {
                self.readyHelper(resolve)
            }
        })
    }

    static init() {
        let acceptableFormats = [
            {
                mineType:'video/webm;codecs=vp8,opus',
                fileExtension: 'webm'
            },
            {
                mineType:'video/webm;codecs=vp9,opus',
                fileExtension: 'webm'
            },
            {
                mineType:'video/webm;codecs=h264,opus',
                fileExtension: 'webm'
            },
            {
                mineType:'video/mp4;codecs=avc1.42E01E, mp4a.40.2',
                fileExtension: 'mp4'
            }
        ]
        for (let i = 0; i < acceptableFormats.length; i++) {
            if (MediaRecorder.isTypeSupported(acceptableFormats[i].mineType)) {
                VideoContext.settings = acceptableFormats[i]
                return true
            }
        }
        return false
    }

}
