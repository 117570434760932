import { runTest } from './jspsych.js';
import axios from 'axios';
import { API_URL } from './config.js';
import { VideoContext } from './video.js';
import * as Sentry from "@sentry/browser";

Sentry.init({
    dsn: "https://b09c0658846d6b92758e1e7026ce8968@o4507998346608640.ingest.de.sentry.io/4507998349557840",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });

let params = new URLSearchParams(window.location.search);
let metadata_id = params.get('metadata_id');
let study_id = params.get('study_id');

if (metadata_id == null || study_id == null) {
    alert("Invalid URL. Please contact the experimenter for a valid URL.")
    window.close();
} else {
    if (!VideoContext.init()) {
        console.error("VideoContext failed to initialize")
        alert("Your browser does not support MediaRecorder. Please use Safari, Chrome or Edge.")
        window.close();
    }
    else {
        console.log("VideoContext initialized. MineType: " + VideoContext.settings.mineType)
        axios.get(`${API_URL}/query?metadata_id=${metadata_id}`)
            .then((response) => {
                runTest({
                    query_data: response.data,
                    study_id: study_id,
                    enable_onedrive: true,
                    enable_downloading: false,
                    metadata_id: metadata_id
                });
            }).catch((error) => {
                console.error(error);
                alert("Error to fetch metadata. Please contact the experimenter.")
            })
    }
}