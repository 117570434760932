import JSZip from 'jszip'
import { VideoContext } from './video';

export default class TaskData {

    constructor() {
        this.metadata = null;
        this.videoData = {};
        this.textData = {}
        this.participantID = null;
        this.metadataID = null
    }

    setMetadataID(metadata_id) {
        this.metadataID = metadata_id;
    }

    setBaseMetadata(metadata) {
        this.metadata = metadata;
    }

    setParticipantID(participantID) {
        this.participantID = participantID;
    }

    addVideoData(key, video) {
        this.videoData[key] = video;
    }

    addTextData(key, text) {
        this.textData[key] = text;
    }

    getMetadata() {
        return {
            ...this.metadata,
            participantID: this.participantID,
            metadataID: this.metadataID,
            results: this.textData
        }
    }
}

export class UploadingTask {

    constructor(name, uploadTask) {
        this.name = name;
        this.uploadTask = uploadTask;
        this.status = "pending";
        this.retry();
    }

    retry() {
        let self = this;
        console.log("Start uploading: " + this.name);
        this.uploadTask()
        .then((result) => {
            if (result.status === 200) {
                console.log("Upload successful: " + this.name);
                this.status = "success";
            } else {
                console.log("Upload failed: " + this.name);
                console.log(result);
                self.retry();
            }
        }).catch((error) => {
            console.log("Upload failed: " + this.name);
            console.log(error);
            self.retry();
        });
    }

}