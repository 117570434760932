import { ParameterType } from "jspsych";

const info = {
    name: 'plugin-uploading-task-checker',
    parameters: {
        tasks: {
            type: ParameterType.FUNCTION,
            default: []
        }
    }
}

class PluginUploadingTaskChecker {

    constructor(jsPsych) {
        this.jsPsych = jsPsych;
    }

    trial(display_element, trial) {

        let html = ''
        let tasks = trial.tasks()

        for (let task of tasks){
            html += `<p id='task-${task.name}'>${task.name} - ${task.status}</p>`;
        }

        display_element.innerHTML = `<div style="
        display: flex;
        width: 95vw;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        ">` + "<h1>Uploading</h1>" + html + `</div>`;

        let interval_id = setInterval(() => {
            for (let task of tasks) {
                display_element.querySelector(`#task-${task.name}`).innerText = `${task.name} - ${task.status}`;
            }
            for (let t of tasks) {
                if (t.status === 'pending') {
                    return;
                }
            }
            clearInterval(interval_id);
            this.jsPsych.finishTrial();
        }, 1000)

    }

}

PluginUploadingTaskChecker.info = info;

export default PluginUploadingTaskChecker;
